import { useEffect, useState } from "react";
import axios from "axios";
import Card from "./components/Card";
import Header from "./components/Header";
import PostForm from "./components/PostForm";
import UsernameForm from "./components/UsernameForm";

function App() {
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState({ username: "" });
  useEffect(() => {
    async function getData() {
      let res = await axios.get(process.env.REACT_APP_API_BASE + "/posts");
      setPosts(res.data);
    }
    getData();
  }, []);

  const [addPost, setAddPost] = useState(false);
  const pushPost = (post) => {
    setPosts((posts) => [post, ...posts]);
  };

  const registerUser = async (username) => {
    if (username === "") {
      return;
    }

    let res = await axios.post(process.env.REACT_APP_API_BASE + "/register", {
      username: username,
    });
    setUser(res.data);
  };

  return (
    <div className="App">
      {user.username === "" && <UsernameForm setUsername={registerUser} />}
      {addPost && (
        <PostForm
          username={user.username}
          onClose={() => setAddPost(false)}
          insertPost={pushPost}
        />
      )}
      <Header avatar={user.avatar} />
      <div
        style={{
          height: "calc(100vh - 64px)",
          width: "100vw",
          overflow: "auto",
          backgroundColor: "#f7f9fb",
        }}
      >
        <div
          style={{
            width: "60%",
            padding: 10,
            paddingBottom: 0,
            margin: "auto",
            overflow: "auto",
          }}
        >
          <button
            className="card-shadow"
            style={{
              width: "100%",
              backgroundColor: "#922b21",
              color: "white",
              border: "none",
              padding: "20px 10px 20px 10px",
              fontSize: "1rem",
              borderRadius: 2,
              marginBottom: 10,
            }}
            onClick={() => setAddPost(true)}
          >
            New Post
          </button>
          {posts.map((post) => (
            <Card
              title={post.title}
              content={post.content}
              username={post.username}
              avatar={post.avatar}
              createdAt={post.createdAt}
              type={post.type}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
