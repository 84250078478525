import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

function Card({ type, title, content, username, avatar, createdAt }) {
  function renderContent() {
    switch (type) {
      case "image":
        return (
          <img
            src={content}
            style={{ width: "100%", height: "auto" }}
            alt="content"
          />
        );
      case "link":
        return null;
      default:
        return <p dangerouslySetInnerHTML={{ __html: content }} />;
    }
  }

  function renderTitle() {
    switch (type) {
      case "link":
        return (
          <a href={content} style={{ color: "black" }} target="blank">
            {title}
          </a>
        );
      default:
        return title;
    }
  }

  return (
    <div
      className="card-shadow"
      style={{
        backgroundColor: "white",
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 2,
        marginBottom: 10,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          src={avatar}
          style={{
            width: 48,
            height: 48,
            borderRadius: 1000,
            border: "1px solid #d6dbdf",
          }}
          alt={username}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <p style={{ color: "#212f3c", margin: 0 }}>{username}</p>
          <p style={{ color: "#212f3c", margin: 0, fontSize: "0.75rem" }}>
            {dayjs(createdAt * 1000).fromNow()}
          </p>
        </div>
      </div>
      <div style={{ paddingTop: 10 }}>
        <p
          style={{
            color: "#212f3c",
            fontSize: "1.5rem",
            margin: 0,
            textAlign: "left",
            marginBottom: 10,
          }}
        >
          {renderTitle()}
        </p>
        <p
          style={{
            color: "#212f3c",
            fontSize: "1rem",
            margin: 0,
            textAlign: "left",
          }}
        >
          {renderContent()}
        </p>
      </div>
    </div>
  );
}

export default Card;
