import { useRef } from "react";

function UsernameForm({ onClose, setUsername }) {
  const nameRef = useRef();

  async function handleSubmit(e) {
    e.preventDefault();
    setUsername(nameRef.current.value);
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <div
        className="card-shadow"
        style={{
          borderRadius: 5,
          backgroundColor: "white",
          width: "40%",
          position: "relative",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ fontSize: "1.5rem", margin: 0, marginBottom: 10 }}>
            Hi 👋 Pick a username to get started.
          </p>
        </div>
        <form>
          <div style={{ marginBottom: 10 }}>
            <input
              type="text"
              ref={nameRef}
              style={{
                width: "100%",
                padding: 10,
                border: "none",
                backgroundColor: "#EBEDEF",
                borderRadius: 5,
                fontSize: "1rem",
                marginTop: 10,
              }}
              placeholder="Ex: thesysarch"
            />
          </div>
          <button
            type="submit"
            className="card-shadow"
            style={{
              width: "100%",
              backgroundColor: "#922b21",
              color: "white",
              border: "none",
              padding: "20px 10px 20px 10px",
              fontSize: "1rem",
              borderRadius: 2,
              marginBottom: 10,
            }}
            onClick={handleSubmit}
          >
            Let's go!
          </button>
        </form>
      </div>
    </div>
  );
}

export default UsernameForm;
