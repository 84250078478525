import axios from "axios";
import { useRef } from "react";

function PostForm({ username, onClose, insertPost }) {
  const titleRef = useRef();
  const contentRef = useRef();

  async function createPost(e) {
    e.preventDefault();
    let formdata = {
      username: username,
      type: "text",
      title: titleRef.current.value,
      content: contentRef.current.value,
    };

    let res = await axios.post(
      process.env.REACT_APP_API_BASE + "/posts",
      formdata
    );
    insertPost(res.data);
    onClose();
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <div
        className="card-shadow"
        style={{
          borderRadius: 5,
          backgroundColor: "white",
          width: "40%",
          position: "relative",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ fontSize: "1.5rem", margin: 0, marginBottom: 10 }}>
            Create a new post
          </p>
          <button
            style={{
              border: "none",
              marginLeft: "auto",
              backgroundColor: "transparent",
              fontSize: "1.5rem",
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <form>
          <div style={{ marginBottom: 10 }}>
            <span>Title</span>
            <input
              type="text"
              ref={titleRef}
              style={{
                width: "100%",
                padding: 10,
                border: "none",
                backgroundColor: "#EBEDEF",
                borderRadius: 5,
                fontSize: "1rem",
                marginTop: 10,
              }}
              placeholder="Ex: My First Post"
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <span>Content (Optional)</span>
            <textarea
              type="text"
              ref={contentRef}
              style={{
                width: "100%",
                padding: 10,
                backgroundColor: "#EBEDEF",
                border: "none",
                borderRadius: 5,
                resize: "none",
                height: "15rem",
                fontSize: "1rem",
                marginTop: 10,
              }}
              placeholder="Ex: Hi everyone! This is my first post."
            />
          </div>
          <button
            type="submit"
            className="card-shadow"
            style={{
              width: "100%",
              backgroundColor: "#922b21",
              color: "white",
              border: "none",
              padding: "20px 10px 20px 10px",
              fontSize: "1rem",
              borderRadius: 2,
              marginBottom: 10,
            }}
            onClick={createPost}
          >
            Post
          </button>
        </form>
      </div>
    </div>
  );
}

export default PostForm;
