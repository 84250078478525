function Header({ avatar }) {
  return (
    <div
      style={{
        height: 64,
        padding: 10,
        backgroundColor: "white",
        borderBottom: "1px solid #D6DBDF",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: "#922b21",
          fontSize: "1.5rem",
          fontWeight: 500,
          width: "20%",
        }}
      >
        Cloudcamp
      </span>
      {avatar && (
        <img
          src={avatar}
          style={{
            width: 48,
            height: 48,
            borderRadius: 1000,
            marginLeft: "auto",
          }}
          alt="user"
        />
      )}
    </div>
  );
}

export default Header;
